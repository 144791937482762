import {NavEntry} from 'context/navPropsContext';
import {useCallback, useContext} from 'react';

import {
  TDatalayerMenuStyle,
  TMenuDatalayerType,
  getHeaderMenuDataLayer,
} from 'utils/tagManagerHelpers/gtmEventsParsing';

import {NextLink, Typography} from 'components/basic-components';

import styles from './Menu.module.scss';
import { ConsentContext } from 'components/section-components/ConsentManager';

export const DropDownLink = ({
  link,
  closeMenu,
  index,
  type,
  menustyle,
}: {
  link: NavEntry;
  closeMenu: () => void;
  index: number;
  type: TMenuDatalayerType;
  menustyle: TDatalayerMenuStyle;
}) => {
  const {pushDataLayer} = useContext(ConsentContext);

  const onClick = useCallback(() => {
    pushDataLayer(getHeaderMenuDataLayer({...link, index, type, menustyle}));
    closeMenu();
  }, [closeMenu, index, link, type, menustyle, pushDataLayer]);
  return (
    <li key={link?.id} className={styles.menuItem}>
      <Typography variant="buttonCards" sx={{cursor: 'pointer'}}>
        <NextLink onClick={onClick} className={styles.link} href={`${link.href}`}>
          {link.title}
        </NextLink>
      </Typography>
    </li>
  );
};
