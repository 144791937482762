import {FooterMenuGroup} from 'context/navPropsContext';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useContext} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {palette} from 'styles/mui/scss';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from 'components/basic-components';
import {NextLink} from 'components/basic-components/NextLink/Link';
import {ConsentContext} from 'components/section-components/ConsentManager';

import styles from './Footer.module.scss';

const FooterMobile = ({footerMenuGroups}: {footerMenuGroups: FooterMenuGroup[]}) => {
  const {t} = useTranslation('common');
  const {openModal} = useContext(ConsentContext);
  return (
    <>
      {footerMenuGroups.map((group: FooterMenuGroup, index: number) => {
        const chunkedMenuLinks = [];
        let splittedStations;
        if (group?.title !== 'Stationen') {
          for (let i = 0; i < group.menuLinks.length; i += 4) {
            chunkedMenuLinks.push(group.menuLinks.slice(i, i + 4));
          }
        } else {
          splittedStations = [
            group?.menuLinks.slice(0, Math.floor(group?.menuLinks.length / 2)),
            group?.menuLinks.slice(Math.floor(group?.menuLinks.length / 2)),
          ];
        }

        return (
          <Accordion
            sx={{backgroundColor: palette.colorPrimary}}
            variant="mobile"
            key={`${group.title}-${index}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{width: '24px', height: '24px'}} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{lineHeight: '30px'}} variant="footerCards">
                {group?.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={styles.footerMobileWrapper}>
                {chunkedMenuLinks.map((chunk, index) => {
                  return (
                    <Box key={index} className={styles.footerMobileLinkBox}>
                      {chunk.map(entry => {
                        if (
                          entry.title.toLowerCase() === 'datenschutz-einstellungen' ||
                          entry.title.toLowerCase() === 'data protection settings'
                        ) {
                          return (
                            <Typography
                              key="customDatenschutz"
                              className={styles.footerLinkStyles}
                              sx={{cursor: 'pointer'}}
                              onClick={e => {
                                e.preventDefault();
                                openModal();
                              }}
                            >
                              {t('dataPrivacySettings')}
                            </Typography>
                          );
                        }
                        return (
                          <Typography
                            key={entry.id}
                            sx={{fontWeight: entry?.bold ? 700 : ''}}
                            className={styles.footerLinkStyles}
                          >
                            <NextLink
                              href={entry?.href}
                              target={entry.newWindow ? '_blank' : '_self'}
                            >
                              {entry?.title}
                            </NextLink>
                          </Typography>
                        );
                      })}
                    </Box>
                  );
                })}
              </Box>
              {splittedStations && (
                <Box className={styles.footerMobileWrapper}>
                  <Box className={styles.footerMobileLinkBox}>
                    {splittedStations[0]?.map(station => {
                      return (
                        <Typography
                          key={station.id}
                          sx={{fontWeight: station?.bold ? 700 : ''}}
                          className={styles.footerLinkStyles}
                        >
                          <NextLink
                            href={station?.href}
                            target={station.newWindow ? '_blank' : '_self'}
                          >
                            {station?.title}
                          </NextLink>
                        </Typography>
                      );
                    })}
                  </Box>
                  <Box className={styles.footerMobileLinkBox}>
                    {splittedStations[1]?.map(station => {
                      return (
                        <Typography
                          key={station.id}
                          sx={{fontWeight: station?.bold ? 700 : ''}}
                          className={styles.footerLinkStyles}
                        >
                          <NextLink
                            href={station?.href}
                            target={station.newWindow ? '_blank' : '_self'}
                          >
                            {station?.title}
                          </NextLink>
                        </Typography>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default FooterMobile;
