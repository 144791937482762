import SpinnerContext from 'context/spinnerContext';
// Update path as per your project structure
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useRouter} from 'next/router';
import React, {useCallback, useContext, useState} from 'react';

import {Box, Button, Drawer, Typography} from '@mui/material';

import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import FeedbackForm from './FeedbackForm';
import FeedbackThankYou from './FeedbackThankYou';
import { ConsentContext } from 'components/section-components/ConsentManager';
import { palette } from 'styles/mui/scss';
import {postApiV1LogNotifications} from "lib/api/backend";

const DrawerCloseHeader = ({onClose}) => {
  const {t} = useTranslation('feedback');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: palette.colorDarkGrey, // Adjust to match the dark background in the image
        color: '#fff', // White text
      }}
    >
      <CustomIcon
        onClick={onClose}
        name="chevronLeft"
        height="30px"
        style={{margin: '10px 16px', cursor: 'pointer'}}
      />
      <div
        style={{
          height: 'calc(100% - 10px)',
          width: '1px',
          backgroundColor: 'grey',
        }}
      ></div>
      <Typography onClick={onClose} sx={{ml: 4, cursor: 'pointer', fontWeight: 'bold'}}>
        {t('close')}
      </Typography>
    </Box>
  );
};
export type TFeedBackOptions = 'good' | 'mid' | 'bad';
const FeedbackDrawer = ({open, onClose}) => {
  const [feedback, setFeedback] = useState('');
  const {addRequestWithGeneratedId, removeRequestWithGeneratedId} = useContext(SpinnerContext);
  const {pushDataLayer} = useContext(ConsentContext)
  const [selectedFeedback, setSelectedFeedback] = useState<TFeedBackOptions>(null);
  const {t} = useTranslation('feedback');
  const {reload} = useRouter();
  const [isSent, setIsSent] = useState(false);
  const onCloseHandler = useCallback(() => {
    setFeedback('');
    setSelectedFeedback(null);
    setIsSent(false);
    onClose();
  }, []);
  return (
    <Drawer sx={{zIndex: 9998}} anchor="right" open={open} onClose={onCloseHandler}>
      <DrawerCloseHeader onClose={onCloseHandler} />
      {!isSent ? (
        <FeedbackForm
          feedback={feedback}
          setFeedback={setFeedback}
          selectedFeedback={selectedFeedback}
          setSelectedFeedback={setSelectedFeedback}
          setIsSent={setIsSent}
        />
      ) : (
        <FeedbackThankYou selectedFeedback={selectedFeedback} />
      )}
      <Box
        sx={{
          width: {sm: '420px', xxs: '100vw'},
          maxWidth: '100vw',
          padding: '20px 3%',
          bottom: 0,
          height: "220px",
          position: {xss: 'sticky', md: 'relative'},
          // position: 'sticky',
          backgroundColor: palette.colorLightGrey,
        }}
      >
        <Typography variant="body2">
          {t('old_design_problem')}
        </Typography>
        <Button
          onClick={() => {
            const {id} = addRequestWithGeneratedId();
            onCloseHandler();
            pushDataLayer({
              event: "switch_variant",
              detail: "use_current_flavor" // Variante zu der gewechselt wird (z.B. use_current_flavor oder use_next_flavor)
            });
            postApiV1LogNotifications({
              notifications: [
                {
                  title: 'Frontend switch',
                  body: 'User switched to old/current frontend in feedback dialogue',
                },
              ],
            });
            fetch('/use_current_flavor')
              .then(() => {
                reload();
                removeRequestWithGeneratedId(id);
              })
              .catch(err => {
                reload();
                removeRequestWithGeneratedId(id);
              });
          }}
          color="secondary"
          sx={{mt: "20px"}}
          fullWidth
        >
          {t('switch_to_old_design')}
        </Button>
      </Box>
    </Drawer>
  );
};

export default FeedbackDrawer;
