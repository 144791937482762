import {NavEntry, NavPropsContext} from 'context/navPropsContext';
import SpinnerContext from 'context/spinnerContext';
import {signOut, useSession} from 'next-auth/react';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useRouter} from 'next/router';
import React, {useContext, useEffect, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {NextLink} from 'components/basic-components/NextLink/Link';
import LoginModal from 'components/section-components/login/LoginModal';

import styles from './Menu.module.scss';

const DropDownLogin = () => {
  const {t} = useTranslation('common');
  const router = useRouter();
  const {addRequestWithGeneratedId} = useContext(SpinnerContext);
  const {headerMenuLoggedInLinks} = useContext(NavPropsContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const closeMenu = () => setIsOpen(false);

  const session = useSession();

  const isBusinessClient = session?.data?.user?.roles.includes('ROLE_BUSINESS_CLIENT');

  useEffect(() => {
    if (router.query.loginRequired) {
      setModalOpen(true);
    }
  }, [router.query.loginRequired]);

  const logout = async () => {
    const {resolve} = addRequestWithGeneratedId();
    try {
      await signOut({redirect: false}).then(() => {
        resolve();
      });
    } catch (err) {
      resolve();
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return session?.status === 'authenticated' ? (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <Box className={styles.dropDownLoginWrapper} cy-dataid="Menu_loggedInMenu">
        <button
          aria-label="Logged-in-Menu"
          className={styles.loggedInMenuButton}
          onClick={toggleDropdown}
        >
          <CustomIcon name="login" className={styles.icon} />
          <Typography className={styles.loggedInMenuButtonText} variant="menuLabel">
            {t('myStarcar')}
          </Typography>
        </button>
        {isOpen && (
          <div className={styles.dropDownWrapper}>
            <ul className={styles.ul} cy-dataid="Menu_flyout-menu">
              {headerMenuLoggedInLinks
                .filter(
                  object => isBusinessClient || object.businessClientOnly === isBusinessClient,
                )
                ?.map((link: NavEntry) => {
                  return (
                    <li key={link?.id} className={styles.menuItem}>
                      <Typography
                        variant="buttonCards"
                        sx={{cursor: 'pointer'}}
                        onClick={() => {
                          if (link?.title === 'Logout') {
                            logout();
                            toggleDropdown();
                          }
                        }}
                      >
                        <NextLink
                          onClick={() => setIsOpen(false)}
                          className={styles.link}
                          href={`${link.href}`}
                        >
                          {link.title}
                        </NextLink>
                      </Typography>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </Box>
    </OutsideClickHandler>
  ) : (
    <>
      <button
        onClick={() => setModalOpen(!modalOpen)}
        id="navbarLoginLink"
        className={styles.loginModalButton}
      >
        <CustomIcon name="login" className={styles.icon} />
        <Typography className={styles.loginModalButtonText} variant="menuLabel">
          Login
        </Typography>
      </button>
      <LoginModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default DropDownLogin;
