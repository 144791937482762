import clsx from 'clsx';
import {getLocalePrefix} from 'hooks/useLocalePrefix';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useRouter} from 'next/router';
import React, {FC, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {NextLink} from 'components/basic-components/NextLink/Link';

import {TTranslatedSlugs} from '../Navbar/types';
import styles from './LanguageSwitch.module.scss';

export interface Props {
  translatedSlugs: TTranslatedSlugs | null;
  noMargin?: boolean;
}
const LanguageSwitch: FC<Props> = ({translatedSlugs, noMargin = false}) => {
  const {t} = useTranslation('common');
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const router = useRouter();
  const {asPath, locales, locale, defaultLocale} = router;
  const translatedSlugsObject = translatedSlugs?.reduce(
    (acc, slug) => ({...acc, [slug.locale]: slug}),
    {},
  );
  if (locales?.length <= 1) return null;
  // href needs to be the link without prepended locale and "as" needs to be link with prepended locale for default locale, so prefetching works correctly
  // https://lifesaver.codes/answer/getting-strange-404-before-page-loads
  const languageLinks = locales
    .filter(locale => locale !== 'default')
    .map(locale => (
      <li onClick={closeMenu} key={'langaugeSwitch-' + locale} className={styles.menuItem}>
        <NextLink
          className={styles.link}
          passHref
          as={
            translatedSlugsObject?.[locale]?.slug
              ? `/${getLocalePrefix({locale, defaultLocale, slash: 'back'})}${
                  translatedSlugsObject?.[locale]?.slug
                }`
              : translatedSlugs?.length === locales.length
              ? getLocalePrefix({locale, defaultLocale, slash: 'back-front'})
              : `${getLocalePrefix({locale, defaultLocale, slash: 'front'})}${
                  asPath?.split('?')[0]
                }`
          }
          href={
            translatedSlugsObject?.[locale]?.slug
              ? `/${getLocalePrefix({locale, defaultLocale, slash: 'back'})}${
                  translatedSlugsObject?.[locale]?.slug
                }`
              : translatedSlugs?.length === locales.length
              ? `/${getLocalePrefix({locale, defaultLocale, slash: 'back'})}`
              : `${`/${locale}${asPath?.split('?')[0]}`}`
          }
          locale={false}
        >
          {locale === 'en' ? (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography variant="buttonCards">English</Typography>
            </Box>
          ) : locale === 'de' ? (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography variant="buttonCards">Deutsch</Typography>
            </Box>
          ) : null}
        </NextLink>
      </li>
    ));
  return (
    <>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        <Box className={clsx({[styles.wrapper]: true, [styles.wrapperMargin]: !noMargin})}>
          <button
            aria-label={t('languageSelection')}
            className={styles.flag}
            id="navbarLanguageSwitch"
            onClick={toggleDropdown}
          >
            <CustomIcon name="globe" style={{height: '24px', width: '24px', marginRight: '5px'}} />
            <Typography sx={{fontWeight: 700}}>
              {locale === 'de' ? 'DE' : locale === 'en' ? 'EN' : null}
            </Typography>
          </button>
          {open && (
            <div className={clsx({[styles.dropDown]: true, [styles.dropDownRight]: noMargin})}>
              <ul data-testid="languageSwitch" className={styles.ul}>
                {languageLinks}
              </ul>
            </div>
          )}
        </Box>
      </OutsideClickHandler>
    </>
  );
};

export default LanguageSwitch;
