import {useStarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import {useFormContext} from 'hooks/custom-react-hook-form';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {useRef, useState} from 'react';

import {palette} from 'styles/mui/scss';

import {Box, DateRangePicker, Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import FormInputDistance from '../FormFields/FormInputDistance';
import SelectCategory from '../FormFields/SelectCategory';
import SubmitButton from '../FormFields/SubmitButton';
import StationSelectionFlyout from '../StationSelection/StationSelection';
import styles from './BestPriceRechnerMobile.module.scss';
import FormInputOneWay from "components/section-components/bpr/FormFields/FormInputOneWay";

const BestPriceRechnerMobile = ({presetStation, errors, stations, stationsById, returnStations, getAvailableReturnStations, setOneWayStationsDistance, oneWayStationsDistanceText, setOneWayStationsDistanceText, hideOneWayForCategoryIfStationDoesntSupportIt}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const bprRef = useRef(null);

  const {clearErrors, control, watch, setValue} = useFormContext();

  const fieldValues = watch();

  const handleCloseMenu = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setIsExpanded(!isExpanded);
  };
  const {t} = useTranslation('bpr');
  const { setCalendarOpen, resetDatepicker, resetFormValues} =
    useStarcarDateRangePickerStateContext();
  return (
    <ContentWrapper sx={{backgroundColor: palette.colorBlackAlt}}>
      <Box sx={{margin: '0 3%', padding: '10px 0'}}>
        <Box
          ref={bprRef}
          sx={{
            width: '100%',
            backgroundColor: palette.colorPrimary,
            borderRadius: '2px',
            padding:
              !fieldValues.station &&
              !fieldValues.departure &&
              !fieldValues.arrival &&
              !fieldValues.distance
                ? '18px 10px'
                : '8px 10px 10px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <CustomIcon
            name="magnifyingglass"
            style={{height: '24px', width: '24px', marginRight: '10px'}}
          />
          <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            {(!fieldValues?.station || !fieldValues?.returnStation) &&
            !fieldValues?.departure &&
            !fieldValues?.arrival ? (
              <Typography sx={{fontWeight: 700, color: palette.colorGrey80}}>
                {t('carSearch')}
              </Typography>
            ) : fieldValues.oneWay && fieldValues?.returnStation ? (
              <>
                {fieldValues?.station || fieldValues?.returnStation ? (
                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography className={styles.stationTextOneWay}>
                      {fieldValues?.station ? fieldValues?.stationTextField : null}
                    </Typography>
                    <Typography className={styles.stationTextOneWay}>
                      {fieldValues?.returnStation ? fieldValues?.returnStationTextField : null}
                    </Typography>
                  </Box>
                ): null}
                {(fieldValues?.departure && fieldValues?.departureTime) || (fieldValues?.arrival && fieldValues?.arrivalTime) ? (
                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', '& p': {fontSize: '12px', lineHeight: '18px', color: palette.colorGrey80}}}>
                    <Typography>
                      <strong>
                        {fieldValues?.departure
                          ? `${fieldValues?.departure?.day}.${fieldValues?.departure?.month}.${fieldValues?.departure?.year}`
                          : null}
                      </strong>{' '}
                      {fieldValues?.departureTime}
                    </Typography>
                    <Box sx={{display: 'flex'}}>
                      <Typography>
                        <strong>
                          {fieldValues?.arrival
                            ? `${fieldValues?.arrival?.day}.${fieldValues?.arrival?.month}.${fieldValues?.arrival?.year}`
                            : null}
                        </strong>{' '}
                        {fieldValues?.arrivalTime}
                      </Typography>
                      {fieldValues?.distance ? (
                        <Box sx={{display: 'flex'}}>
                          <Box
                            sx={{width: '1px', margin: '0 5px', backgroundColor: palette.colorGrey10}}
                          />
                          <Typography sx={{fontWeight: 700}}>{`${fieldValues?.distance}${t('distanceUnit')}`}</Typography>
                        </Box>
                      ): null}
                    </Box>
                  </Box>
                ): null}
              </>
            ) : (
              <>
                <Typography sx={{fontWeight: 700, lineHeight: '24px', color: palette.colorGrey80}}>
                  {fieldValues?.station ? fieldValues?.stationTextField : null}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    '& p': {fontSize: '12px', lineHeight: '18px', color: palette.colorGrey80},
                  }}
                >
                  <Typography>
                    <strong>
                      {fieldValues?.departure
                        ? `${fieldValues?.departure?.day}.${fieldValues?.departure?.month}.${fieldValues?.departure?.year}`
                        : null}
                    </strong>{' '}
                    {fieldValues?.departureTime}
                  </Typography>
                  <Typography sx={{margin: '0 5px'}}>-</Typography>
                  <Typography>
                    <strong>
                      {fieldValues?.arrival
                        ? `${fieldValues?.arrival?.day}.${fieldValues?.arrival?.month}.${fieldValues?.arrival?.year}`
                        : null}
                    </strong>{' '}
                    {fieldValues?.arrivalTime}
                  </Typography>
                  <Box sx={{display: 'flex'}}>
                    <Box
                      sx={{width: '1px', margin: '0 5px', backgroundColor: palette.colorGrey10}}
                    />
                    <Typography sx={{fontWeight: 700}}>{`${fieldValues?.distance}${t('distanceUnit')}`}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={`${styles.openedBPR} ${isExpanded ? styles.expanded : null}`}>
        <Box sx={{padding: '0 0 10px 0'}}>
          <SelectCategory />
          <Grid container rowSpacing={2.5} sx={{paddingTop: '10px'}}>
            <Grid xxs={12}>
              <StationSelectionFlyout
                name="stationTextField"
                control={control}
                disabled={!!presetStation}
                errors={errors}
                setStationTextField={(value: string) => {
                  setValue('stationTextField', value, {shouldDirty: true, shouldTouch: true});
                  resetDatepicker();
                  resetFormValues();
                  setCalendarOpen(!fieldValues.oneWay);
                  clearErrors(['station', 'stationTextField']);
                }}
                setStation={station => {
                  setValue('station', station?.id || '', {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  console.log('setStation', station);
                  clearErrors(['station', 'stationTextField']);
                }}
                stations={stations}
                selectedStation={stationsById?.[fieldValues.station] || null}
                value={fieldValues.stationTextField}
                isWidget={false}
                showPopularStations={false}
                isOneWay={fieldValues.oneWay}
                getAvailableReturnStations={getAvailableReturnStations}
                setOneWayStationsDistance={setOneWayStationsDistance}
                setOneWayStationsDistanceText={setOneWayStationsDistanceText}
              />
            </Grid>
            {fieldValues.oneWay ? (
              <Grid xxs={12}>
                <StationSelectionFlyout
                  name="returnStationTextField"
                  control={control}
                  errors={errors}
                  setStationTextField={(value: string) => {
                    setValue('returnStationTextField', value, {shouldDirty: true, shouldTouch: true});
                    setCalendarOpen(true);
                    clearErrors('returnStation');
                  }}
                  setStation={returnStation => {
                    setValue('returnStation', returnStation?.id || '', {shouldDirty: true, shouldTouch: true});
                    clearErrors('returnStation');
                  }}
                  stations={returnStations}
                  selectedStation={fieldValues.returnStation ? stationsById?.[fieldValues.returnStation] : null}
                  value={fieldValues.returnStationTextField}
                  isWidget={false}
                  showPopularStations={false}
                  isOneWay={fieldValues.oneWay}
                  disabled={!fieldValues.station || !fieldValues.stationTextField}
                  getAvailableReturnStations={getAvailableReturnStations}
                  setOneWayStationsDistance={setOneWayStationsDistance}
                  setOneWayStationsDistanceText={setOneWayStationsDistanceText}
                />
              </Grid>
            ): null}
            <Grid xxs={12}>
              <Grid
                container
                justifyContent={'space-between'}
                flexDirection={'row'}
              >
                <Grid xxs={hideOneWayForCategoryIfStationDoesntSupportIt ? 12 : 6}>
                  <FormInputOneWay
                    hideOneWayForCategoryIfStationDoesntSupportIt={hideOneWayForCategoryIfStationDoesntSupportIt}
                    control={control}
                    checkboxColor={palette.colorWhite}
                    labelColor={palette.colorMidGrey}
                    checkboxSize={'small'}
                  />
                </Grid>
                <Grid xxs={6} container justifyContent={'flex-end'}>
                  {oneWayStationsDistanceText ? (
                    <Box className={styles.oneWayDistanceBox}>
                      <Typography variant="formLabeling" sx={{fontSize: '10px'}}>
                        {t('oneWayDistance')}: {oneWayStationsDistanceText}
                      </Typography>
                    </Box>
                  ): null}
                </Grid>
              </Grid>
            </Grid>
            <Grid xxs={12}>
              <DateRangePicker isWidget={false} />
            </Grid>
            <Grid xxs={12}>
              <FormInputDistance
                control={control}
                errors={errors}
                isWidget={false}
                disabled={!(fieldValues?.stationTextField && fieldValues?.station || fieldValues?.distance)}
              />
            </Grid>
            <Grid xxs={12} sx={{marginTop: '5px'}}>
              <SubmitButton
                opacity={
                  (fieldValues.arrival &&
                    fieldValues.departure &&
                    fieldValues.station &&
                    !Object.keys(errors)?.length &&
                    1) ||
                  0.7
                }
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: palette.colorPrimary,
              cursor: 'pointer',
              width: 'fit-content',
              margin: '20px auto 10px',
            }}
            className="close-bpr-modal-button"
            onClick={handleCloseMenu}
          >
            <CustomIcon name="chevronUp" style={{height: '24px', width: '24px'}} />
            <Typography
              className="close-bpr-modal-button"
              sx={{lineHeight: '24px', fontWeight: 700, marginLeft: '5px'}}
            >
              {t('close')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default BestPriceRechnerMobile;
